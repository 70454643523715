import React from 'react';
import '../../App.css';
import Form from '../form'

import Fade from 'react-reveal/Fade';
import Address from '../Address';
export default function SignUp() {
  return <div className='sign-up'> 
  <Fade right><Form/>
  <Address/>
  </Fade>


</div>
  
  

}
