import React from 'react'
import { Form, Input, Button } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import './form.css';


const layout = {
  labelCol: {
    // span: 9,
    // offset: 9,
  },
  wrapperCol: {
    // span: 6,
    // offset: 9,
  },
};
const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'is not valid !',
    number: 'is not valid!',
  },
  
};
const Demo = () => {
  const [form] = Form.useForm();
  const onFinish = values => {
   
  
    
  };
  const onReset = () => {
    form.resetFields();
  };
 

  return (
   
      <div className="form" >
        <h1 className="form_head" >Get In Touch With Us</h1>
        <Form {...layout} form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          colon={false}
          layout={"vertical"}
        >
        
          <Form.Item key='1'>
          </Form.Item>
          <Form.Item key='2'
            name={['user', 'name']}
            label={<span>Name</span>}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Please enter your Full Name" />
          </Form.Item>

          <Form.Item key='3'
            name={['user', 'email']}
            label={<span>Email-id</span>}
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <Input placeholder="Please enter a valid Email-id" />
          </Form.Item>
          <Form.Item key='4'
            name={['user', 'pno']}
            label={<span>Contact Number</span>}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <PhoneInput country='in' />
          </Form.Item>
          <Form.Item key='7' name={['user', 'message']} label={<span>Your message to us</span>}>
            <Input.TextArea style={{ borderRadius: "5px" }} className="textarea" placeholder="Message" />
          </Form.Item>

         

          <Form.Item key='9' wrapperCol={{ ...layout.wrapperCol, }}>
            <Button type="primary" block htmlType="submit" onClick={onReset}  >
              Submit
    </Button>
          </Form.Item>
        </Form>
      </div>
   
  );
};

export default Demo