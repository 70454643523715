import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import Slide from 'react-reveal/Slide';
import Rotate from 'react-reveal/Rotate';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Image from './pages/image/glass-garden.png'
import Image1 from './pages/videos/glogo1.png'

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
 

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  
  
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
           
          <Rotate top left>     <img src={Image1} alt="img" className="logo" ></img></Rotate>
            <Slide right><img src={Image} alt="glass" className="glass"/></Slide>
          </Link>
          
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
           
            <li className='nav-item'>
              <Link
                to='/products'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li>
            <li className='nav-item'>
            <Link
              to='/services'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Service&Training
            </Link>
          </li>
            <li>
            <Link
            to='/gallery'
            className='nav-links'
            onClick={closeMobileMenu}
          >
            Gallery
          </Link>
        </li>
          <li>
            <Link
              to='/about'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              AboutUs
            </Link>
          </li>
            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
               Contact Us
              </Link>
            </li>
            
          </ul>
          {button && <Button buttonStyle='btn--outline'>ContactUs</Button>}
         
        </div>
      </nav>
    </>
  );
}

export default Navbar;
