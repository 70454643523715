import React from 'react';
import cx from 'classnames';
import Pdf from 'react-to-pdf';
import styles from './Invoice.module.css';
import './index.css'

export default function Invoice() {
    const ref = React.createRef();
  return (
    <div>
      <Pdf targetRef={ref} filename="div-blue.pdf" >
        {({ toPdf }) => (
          <button style={{display:'flex',justifyContent:'center'}} onClick={toPdf}>Generate pdf</button>
        )}
      </Pdf>
     
      <div  ref={ref} className="outline"  >
        <table  className={styles.mainTable} border="bordered" cellSpacing={0} ref={ref}>
          <tr >
            <td colSpan={3} rowSpan={4} className={cx( styles.noRightBorder)}><img style={{width:"40%"}} src="/videos/glogo1.png" alt="Glass-garden
            " /></td>
            <td colSpan={5} className={cx(styles.center, styles.noSideBorder, styles.noBottomBorder)}>
              M&M GLASS GARDEN
            </td>
            <td colSpan={3} rowSpan={4} className={cx(styles.center, styles.noLeftBorder)}>
              Original for Recipient
            </td>
          </tr>
          <tr>
            <td colSpan={5} className={cx(styles.center, styles.noBorder)}>
              No 117,Ground Floor,Nagondanahalli Main road,Opposite to NVT quality Life Square Villas,Whitefield,Bangalore 560066
            </td>
          </tr>
          <tr>
            <td colSpan={5} className={cx(styles.center, styles.noBorder)}>
              
            </td>
          </tr>
          <tr>
            <td colSpan={5} className={cx(styles.center, styles.bold, styles.noSideBorder, styles.noTopBorder)}>
              GSTIN: 29EFAPS5512A1ZO
            </td>
          </tr>
          <tr>
            <td colSpan={11} className={styles.center}>Telephone:  9538584767, 7338143033</td>
          </tr>
          <tr>
            <td colSpan={11} className={cx(styles.center, styles.heading)}>Tax Invoice</td>
          </tr>
          <tr>
            <td colSpan={6}>
              <span className={styles.bold}>Invoice No:</span>
              {' '}
              <input  style={{borderColor:"white"}}></input>
            </td>
            <td colSpan={5}>Transport Mode:<input  style={{borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={6}>
              <span className={styles.bold}>Invoice Date:</span>
              {' '}
              <input type="date"  style={{borderColor:"white"}}></input>
            </td>
            <td colSpan={5}>Vehicle number:<input  style={{borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={5} className={styles.bold}>
              Reverse Charge (Y/N):
            </td>
            <td colSpan={1}>N</td>
            <td colSpan={5}>Date of Supply:<input  style={{borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={4}>
              <span className={styles.bold}>State:</span>
              {' '}
              <input  style={{width:"70%",borderColor:"white"}} placeholder="Karnataka"></input>
            </td>
            <td colSpan={1} className={styles.bold}>Code</td>
            <td colSpan={1}><input  style={{width:"46%",borderColor:"white"}} placeholder="29"></input></td>
            <td colSpan={5}>
              <span className={styles.bold}>Place of Supply:<input  style={{borderColor:"white"}}></input></span>
              {' '}
            </td>
          </tr>
          <tr>
            <td colSpan={11}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6} className={cx(styles.bold, styles.center)}>Bill to Party</td>
            <td colSpan={5} className={cx(styles.bold, styles.center)}>Ship to Party</td>
          </tr>
          <tr>
            <td colSpan={6} className={styles.bold}>Name: <input  style={{borderColor:"white"}}></input></td>
            <td colSpan={5} className={styles.bold}>Name: <input  style={{borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={6}>
              Address: <input  style={{width:"100%",borderColor:"white"}} placeholder="line1"></input>
              <br />
              <input  style={{width:"100%",borderColor:"white"}} placeholder="line2"></input>
            </td>
            <td colSpan={5}>
            Address: <input  style={{width:"100%",borderColor:"white"}} placeholder="line1"></input>
            <br />
            <input  style={{width:"100%",borderColor:"white"}} placeholder="line2"></input>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>GSTIN: <input  style={{borderColor:"white"}}></input></td>
            <td colSpan={5}>GSTIN: <input  style={{borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={4}>State: <input  style={{width:"70%",borderColor:"white"}} placeholder="Karnataka"></input></td>
            <td colSpan={1}>Code</td>
            <td colSpan={1}><input  style={{width:"46%",borderColor:"white"}} placeholder="29"></input></td>
            <td colSpan={3}>State: <input  style={{width:"70%",borderColor:"white"}} placeholder="Karnataka"></input></td>
            <td colSpan={1}>Code</td>
            <td colSpan={1}><input  style={{width:"46%",borderColor:"white"}} placeholder="29"></input></td>
          </tr>
          <tr>
            <td colSpan={11}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={1} rowSpan={2} className={styles.center}>
              S.
              <br />
              No.
            </td>
            <td colSpan={1} rowSpan={2} className={styles.center}>Product Description</td>
            <td colSpan={1} rowSpan={2} className={styles.center}>
              HSN
              <br />
              Code
            </td>
            <td colSpan={1} rowSpan={2} className={styles.center}>Qty</td>
            <td colSpan={1} rowSpan={2} className={styles.center}>Rate</td>
            <td colSpan={1} rowSpan={2} className={styles.center}>Amount</td>
            <td colSpan={1} rowSpan={2} className={styles.center}>Discount</td>
            <td colSpan={1} rowSpan={2} className={styles.center}>
              Taxable
              <br />
              Value
            </td>
            <td colSpan={2} className={styles.center}>GST</td>
            <td colSpan={1} rowSpan={2} className={cx(styles.center, styles.h3)}>Total</td>
          </tr>
          <tr>
            <td colSpan={1}>Rate</td>
            <td colSpan={1}>Amount</td>
          </tr>
          <tr className={styles.firstRow}>
            <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td><input  style={{borderColor:"white"}}></input>
            <input  style={{borderColor:"white"}}></input></td>
            <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr className={styles.innerRow}>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td><input  style={{borderColor:"white"}}></input>
          <input  style={{borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr className={styles.innerRow}>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td><input  style={{borderColor:"white"}}></input>
          <input  style={{borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr className={styles.innerRow}>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td><input  style={{borderColor:"white"}}></input>
          <input  style={{borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr className={styles.lastRow}>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td><input  style={{borderColor:"white"}}></input>
          <input  style={{borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          <td className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={3} className={cx(styles.h2, styles.center)}>Total</td>
            <td colSpan={1} className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={1}>&nbsp;</td>
            <td colSpan={1} className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={1} className={styles.center}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={1} className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={1}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={1} className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={1} className={styles.right}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={7}>Total Invoice amount in words</td>
            <td colSpan={3}>Total Amount before Tax</td>
            <td colSpan={1}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={7} rowSpan={2}>
            <input  style={{width:"100%",borderColor:"white"}}></input>
            </td>
            <td colSpan={3}>Add SGST@9%</td>
            <td colSpan={1}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={3}>Add CGST@9%</td>
            <td colSpan={1}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={4} className={cx(styles.center, styles.bold)}>Bank Details</td>
            <td colSpan={3} rowSpan={4}>&nbsp;</td>
            <td colSpan={3} className={styles.bold}>Total Amount after Tax:</td>
            <td colSpan={1} className={styles.bold}><input  style={{width:"100%",borderColor:"white"}}></input></td>
          </tr>
          <tr>
            <td colSpan={4}>Bank A/C: <input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={3}>GST on Reverse Charge</td>
            <td colSpan={1}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={4}>Bank IFSC: <input  style={{width:"100%",borderColor:"white"}}></input></td>
            <td colSpan={4} className={cx(styles.center, styles.certified, styles.noBottomBorder)}>
              Certified that the particulars given above are true and correct
            </td>
          </tr>
          <tr>
            <td colSpan={4} rowSpan={2}>&nbsp;</td>
            <td
              colSpan={4}
              rowSpan={1}
              className={cx(styles.signatureGap, styles.center, styles.noVerticalBorder)}>
              For GlassGarden
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={styles.center}>Common Seal</td>
            <td colSpan={4} className={cx(styles.center, styles.noTopBorder)}>Authorized signatory</td>
          </tr>
          
        </table>
        </div>
      </div>
    
  );
}
