import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {PhoneOutlined} from '@ant-design/icons'
function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
         <b> M & M Glass Garden </b>
        </p>
        <p className='footer-subscription-text'>
          Handmade stained glass manufactures
        </p>
      
      </section>
      <h2 className="address_footer">Address</h2>
      <p className="address_pfooter">N0.179,Royal Elite Residency Phase 2 Kodipalya Kengeri,
       Banglore - 560060 </p>
      <h3 className="address_footer"><PhoneOutlined />+91 9538584767</h3>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            
            <Link to='/about'><h2>About Us</h2></Link>
           
          </div>
          <div class='footer-link-items'>
         
            <Link to='/sign-up'>   <h2>Contact Us</h2></Link>
           
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
           
            <Link to='/products'> <h2>Our Products</h2></Link>
          
          </div>
          <div class='footer-link-items'>
            
            <Link to='/services'><h2>Services</h2></Link>
            
          </div>
        </div>
      </div>
      <p class="crps">© 2021 Glass Garden . All Rights Reserved.</p>
      <section class='social-media'>
        <div class='social-media-wrap'>
         
        
       
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
     
      </section>
    </div>
  );
}

export default Footer;
