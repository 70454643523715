import React from 'react'
import { Link } from 'react-router-dom';
import './Training.css'
import Image from './pages/image/Training1.jpg'
import Image1 from './pages/image/Training.jpg'
import Image2 from './pages/image/Training2.jpg'
import Fade from 'react-reveal/Fade';

function Training() {
  var names = ['Decorative Glass Art', 'Custom Stained Glass', 'Church Buildings', 'Professional Stained Glass Repair & Restoration', 
  'Custom Window Design','Custom Windows,Door & Entryway','Stained & Art Glass Consulting','Stained Glass Appraisal',
  'Inspection Service of Windows'];
  var TrainingList=['Genuine Stained Glass Courses','Stained Glass Mosaic Courses','Painted Stained Glass Course']
  return (
    <div >
    <h1  className="training" >Services & Training </h1>
    <Fade left>
    <table border="1" bordercolor="transparent"  className="table" >
    <h1 className="service_h1"  >Services</h1>
    <p  className="service_p">The term stained glass refers to coloured glass as a material and to works created from it.
    Throughout its thousand-year history, the term has been applied almost exclusively to
    the windows of churches and other significant religious buildings.
    Although traditionally made in flat panels and used as windows,
      the creations of modern stained glass artists also include three-dimensional structures and sculpture.</p> 
      <button className="service_button" ><Link to='/sign-up' >Contact Us</Link></button>
    </table></Fade> <Fade right>
    <img src={Image} alt="imag" className="service_img"/></Fade>
    <div  className="service_div">
    <h3 className="service_list">Service List</h3>
      
      <ul className="list_service">
        {names.map(function (name, index) {
          return <li style={{fontFamily:"cursive"}} key={index}>{name}</li>;
        })}
      </ul>
      </div>
    
    <h1 className="training">Training</h1>
    <Fade left> <img src={Image1} alt="img" /></Fade>
    <img src={Image2} alt="img" className="work"/>
    <div  className="training_div">
    <h3 className="service_list">Training List</h3>
      
      <ul className="list_service">
        {TrainingList.map(function (name, index) {
          return <li style={{fontFamily:"cursive"}} key={index}>{name}</li>;
        })}
      </ul>
      </div>
     
      <br></br>
      
    </div>
  )
}

export default Training
