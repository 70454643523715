import React from 'react'
import {PhoneOutlined,MailOutlined} from '@ant-design/icons'
import './Address.css';
function Address() {
    return (
       <div className="address">
       <h3 className="address_h1">Address</h3>
<p className="address-p">N0.117,Ground Floor,Nagondahalli Main Road,Opposite to Nvt Quality Life Sqaure Villas,
WhiteField Banglore,560066 </p>
<h6 className="address-h6"><PhoneOutlined />+91 7338143033</h6>

<form action="https://wa.me/+919538584767/">
    <input type="submit" value=" " className="whatsapp" />
</form>
<h3 className="comp">Sampath Kumar</h3>
<h6 className="address-h6"> Propertior</h6>
<h6 className="address-h6"><PhoneOutlined />+91 9538584767</h6>
<h6 className="address-h6"><MailOutlined />studiojmj17@gmail.com</h6>


</div>
    )
}

export default Address
