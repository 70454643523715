import React from 'react';
import '../../App.css';
import Training from '../Training'
export default function Services() {
  return <div className='services'> 
  
  <Training/>
  
  
  </div>;
}
