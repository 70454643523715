import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import About from "./components/pages/About";
import SignUp from "./components/pages/SignUp";
import LogIn from "./components/pages/LogIn";
import Invoice from "./components/pages/Invoice";
import Lamp from "./components/pages/Lamp";
import Window from "./components/pages/Window";
import Gift from "./components/pages/Gift";
import Domes from "./components/pages/Domes";
import SkyLights from "./components/pages/SkyLights";
import SunCatchers from "./components/pages/SunCatchers";
import DoorPanel from "./components/pages/DoorPanel";
import Gallery from "./components/pages/Gallery";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/products" component={Products} />
          <Route path="/about" component={About} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/login" component={LogIn} />
          <Route path="/invoice" component={Invoice} />
          {/*<Route path="/lamp" component={Lamp} />*/}
          <Route path="/window" component={Window} />
          <Route path="/gift" component={Gift} />
          {/*<Route path='/domes' component={Domes} />*/}
          <Route path="/skylights" component={SkyLights} />
          <Route path="/suncatchers" component={SunCatchers} />
          {/*<Route path='/door' component={DoorPanel} />*/}
          <Route path="/gallery" component={Gallery} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
