import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

import Image from "./pages/image/lamp2.jpg";
import Image1 from "./pages/image/Image11.jpg";
import Image2 from "./pages/image/suncatchers1.jpg";
import Image3 from "./pages/image/domes.jpg";
import Image4 from "./pages/image/skylights.jpg";
import Image5 from "./pages/image/gift.jpeg";
import Image6 from "./pages/image/PicsArt (16).jpg";
function Cards() {
  return (
    <div className="cards">
      <h1 style={{ marginTop: "-4%" }}> Stained Glasses</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            {/*<CardItem
              label="Tiffany Lamp"
              src={Image}
              text="A Tiffany lamp is a type of lamp with a glass shade made with glass designed by Louis Comfort Tiffany. 
              The most famous was the stained leaded glass lamp. Tiffany lamps are considered part of the Art Nouveau movement."
              path="/lamp"
            />*/}
          </ul>
          <ul className="cards__items">
            <CardItem
              src={Image1}
              text="Stained glass windows were once used to educate people who could not read.
             By looking at the painted scenes in the glass, people could learn about the stories of the Bible and religious teachings."
              label="Window Panels"
              path="/window"
            />
            <CardItem
              src={Image2}
              text="A suncatcher or light catcher is a small reflective, refractive.A suncatcher is like the optical equivalent of a wind chime. "
              label="SunCatchers"
              path="/suncatchers"
            />
          </ul>
          <ul className="cards__items">
            {/* <CardItem
              src={Image3}
              text='A dome is an architectural element similar to the hollow upper half of a sphere,
              there is significant overlap with the term cupola, which may also refer to a dome or a structure on top of a dome.'
              label='Domes'
              path='/domes'
           />*/}
            <CardItem
              src={Image4}
              text="Sunlight is a portion of the electromagnetic radiation given off by the Sun. 
              On Earth, sunlight is scattered and filtered through Earth atmosphere and is obvious as daylight when the Sun is above the horizon."
              label="SkyLight"
              path="/skylights"
            />
            <CardItem
              src={Image5}
              text="A gift or a present is an item given to someone without the expectation of payment or anything in return. 
            An item is not a gift if that item is already owned by the one to whom it is given. "
              label="GiftItems"
              path="/gift"
            />

            {/*<CardItem
          src={Image6}
          text='Panel doors are, and have been, very popular for quite some time. 
          Their name essentially tells you what they are – the door is crafted not of one single piece of wood or other material,
          but instead is comprised of panels.'
          label='Door Panels'
          path='/door'
          />*/}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
