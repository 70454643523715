import React from 'react';
import '../../App.css';
import Slide from 'react-reveal/Slide';
import Glass from './Glass';

export default function Gallery() {
  const array=["glass1.jpg","PicsArt 5.jpg","PicsArt 2.jpg","PicsArt 4.jpg","PicsArt (10).jpg","PicsArt.png",
 "PicsArt (5).png","PicsArt (17).jpg", "PicsArt (2).png", "PicsArt (9).jpg","PicsArt (19).jpg" ,"PicsArt (11).jpg","PicsArt (6).png","PicsArt (12).jpg",
 "PicsArt (1).jpg","PicsArt (3).png","PicsArt 8.png","PicsArt (4).png","all.png",
 "pic2.png","Image2.jpg", "PicsArt (16).jpg",  "Image10.jpg","Image11.jpg","Image12.jpg" ,"PicsArt (18).jpg","all2.jpg","Image3.jpg", 
  "PicsArt 3.jpg",]
  const images = array.map(image => {
     return <img style={{padding:'1px',    marginTop: '5%' }} alt="imag"  src={require(`./image/${image}`)}  className="zoom"/>});


  return  <div   style={{backgroundColor:"white"}}>
  <h1  className="About-h1"><b>Gallery</b></h1>
  <Slide left> <div >{images  }</div>
  <Glass/> </Slide></div>
}





























// import React from 'react';
// import '../../App.css';
// import makeCarousel from 'react-reveal/makeCarousel';
// import Slide from 'react-reveal/Slide';
// import styled,{css} from 'styled-components';
// import Glass from './Glass';

// const width = '1550px', height='750px';
// const Container = styled.div`
//   border: 1px solid red;
//   position: relative;
//   overflow: hidden;
//   width: ${width};
//   height: ${height};
// `;
// const Arrow = styled.div`
//   text-shadow: 1px 1px 1px #fff;
//   z-index: 100;
//   line-height: ${height};
//   text-align: center;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   font-size: 3em;
//   cursor: pointer;
//   user-select: none;
//   ${props => props.right ? css`left: 90%;` : css`right: 0%;`}
// `;
// const CarouselUI = ({ position, handleClick, children }) => (
//   <Container>
//       {children}
//       <Arrow onClick={handleClick} data-position={position - 1}>{'<'}</Arrow>
//       <Arrow right onClick={handleClick} data-position={position + 1}>{'>'}</Arrow>
//   </Container>
// );
// const Carousel = makeCarousel(CarouselUI);

// export default function Gallery() {
//   const array=["PicsArt (10).jpg","glass1.jpg","PicsArt 5.jpg","PicsArt 2.jpg","PicsArt 4.jpg","PicsArt (10).jpg","PicsArt.png",
//  "PicsArt (5).png","PicsArt (17).jpg", "PicsArt (2).png", "PicsArt (9).jpg","PicsArt (19).jpg" ,"PicsArt (11).jpg","PicsArt (6).png","PicsArt (12).jpg",
//  "PicsArt (1).jpg","PicsArt (3).png","PicsArt 8.png","PicsArt (4).png","all.png",
//  "pic2.png","Image2.jpg", "PicsArt (16).jpg",  "Image10.jpg","Image11.jpg","Image12.jpg" ,"PicsArt (18).jpg","all2.jpg","Image3.jpg", 
//   "PicsArt 3.jpg",]
//   const images = array.map(image => {
//      return (<img alt="imag"  src={require(`./image/${image}`)}  />)});


//   return  <div   >
  
//   <Carousel>
//   <Slide right>
//     <div>
//       {images}
//     </div>
//   </Slide></Carousel></div>}
















// import React from 'react'
// import makeCarousel from 'react-reveal/makeCarousel';
// import Slide from 'react-reveal/Slide';
// import Image from './image/glass1.jpg'
// import Image1 from './image/PicsArt 5.jpg'
// import Image2 from './image/PicsArt 2.jpg'
// import Image3 from './image/PicsArt 4.jpg'
// import Image4 from './image/PicsArt (10).jpg'
// import Image5 from './image/PicsArt.png'

// import styled,{css} from 'styled-components';
// const CarouselUI = ({ position, total, handleClick, children }) => <Container>{children} 
// <Arrow onClick={handleClick} data-position={position - 1}>{'<'}</Arrow>
// <Arrow right onClick={handleClick} data-position={position + 1}>{'>'}</Arrow></Container>;

// const Carousel = makeCarousel(CarouselUI);
// const Container = styled.div`

// position: relative;
// overflow: hidden;
// width: 100%;
// height:550px;

// .image{
//   width:1550px;
//   height:700px;
// }

// @media(max-width:420px){
//   position: relative;
// overflow: hidden;
// width: 100%;
// height:200px;
// .image{
//   width:100%;
//   height:100%;
// }
// `;
// const height='100px'
// const Arrow = styled.div`
//   text-shadow: 1px 1px 1px #fff;
//   z-index: 100;
//   line-height: ${height};
//   text-align: center;
//   position: absolute;
//   top: 30%;
//   width: 10%;
//   font-size: 3em;
//   cursor: pointer;
//   user-select: none;
//   ${props => props.right ? css`left: 90%;` : css`left: 0%;`}
// `;
// function Gallery() {

//   return (
//     <div>
    
//       <Carousel defaultWait={1115000} /*wait for 1000 milliseconds*/ >
        
//         <Slide right>
//           <div  >
//             <img src={Image} alt="glass"  className="image"  ></img>
             
//           </div>
//         </Slide>
//         <Slide right>
//           <div>
         
//           <img src={Image1}  alt="glass"  className="image"/>
       
//           </div>
//         </Slide>
//         <Slide right>
//           <div>
//           <img src={Image2}  alt="glass" className="image" />
         
//           </div>
//         </Slide>
//         <Slide right>
//           <div>
//           <img src={Image3}  alt="glass" className="image" />
         
//           </div>
//         </Slide>
//         <Slide right>
//           <div>
//           <img src={Image4}  alt="glass" className="image" />
         
//           </div>
//         </Slide>
//         <Slide right>
//           <div>
//           <img src={Image5}  alt="glass" className="image" />
         
//           </div>
//         </Slide>
//       </Carousel>
      
//       </div>
  
//   )
// }

// export default Gallery




// import React from 'react'
// import Image from './image/glass1.jpg'
// import Image1 from './image/PicsArt 5.jpg'
//  import Image2 from './image/PicsArt 2.jpg'
// import Carousels from './Carousel'
// function Gallery() {
//   const array=[Image,Image1,Image2]
//   return (
//     <div>
//     <Carousels src={array}/>
    
      
//     </div>
//   )
// }

// export default Gallery


// import React from 'react'
// import { Slide } from 'react-slideshow-image';
// import Image from './image/glass1.jpg'
//  import Image1 from './image/PicsArt 5.jpg'
//   import Image2 from './image/PicsArt 2.jpg'

// const slideImages = ['./image/glass1.jpg','./image/PicsArt 5.jpg','./image/PicsArt 2.jpg'];

// const Gallery = () => {
//     return (
//       <div>
    
//             <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
//               <span>Slide 1</span>
           
//           </div>
        
//             <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
//               <span>Slide 2</span>
        
//           </div>
     
//             <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
//               <span>Slide 3</span>
        
//           </div>
      
//       </div>
//     )
// };

// export default Gallery






// import React from 'react';
// import { Carousel } from 'react-bootstrap';

// import image1 from  './image/glass1.jpg';
// import image2 from './image/PicsArt 5.jpg';
// import image3 from './image/PicsArt 2.jpg';

// const CarouselContainer = () => {
//   return (
//     <Carousel fade={true} pause={false}>
//       <Carousel.Item interval={2000}>
//         <img
//           className="d-block w-100"
//           src={image1}
//           alt="First slide"
//         />
//         <Carousel.Caption>
//           <h3>First slide label</h3>
//           <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item interval={2000}>
//         <img
//           className="d-block w-100"
//           src={image2}
//           alt="Third slide"
//         />
//         <Carousel.Caption>
//           <h3>Second slide label</h3>
//           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//         </Carousel.Caption>
//       </Carousel.Item>
//       <Carousel.Item interval={2000}>
//         <img
//           className="d-block w-100"
//           src={image3}
//           alt="Third slide"
//         />
//         <Carousel.Caption>
//           <h3>Third slide label</h3>
//           <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
//         </Carousel.Caption>
//       </Carousel.Item>
//     </Carousel>
//   )
// }

// export default CarouselContainer;




// import React from 'react'
// import '../../App.css';
// import ImageSlider from './ImageSlider';
// import { SliderData } from './SliderData';


// function Gallery() {
//   return (
//     <ImageSlider slides={SliderData} />
//   )
// }

// export default Gallery


// import React from 'react'
// import AutoplaySlider from 'react-awesome-slider/hoc/autoplay';
// import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation.scss';

 
// const Gallery = (
//   <AutoplaySlider
//     play={true}
//     cancelOnInteraction={false}
//     interval={6000}
//     cssModule={AwesomeSliderStyles}
//   >
//     <div data-src="/path/to/image-0.jpg" />
//     <div data-src="/path/to/image-1.jpg" />
//     <div data-src="/path/to/image-2.jpg" />
//     <div data-src="/path/to/image-3.jpg" />
//   </AutoplaySlider>
// );
// export default Gallery;



// import React from 'react'
// import Carousels from './Carousel'

// function Gallery() {
//   return (
//     <div>
//       <Carousels/>
//     </div>
//   )
// }

// export default Gallery
