import React from "react";
import "../../App.css";
import Slide from "react-reveal/Slide";

function Window() {
  const array = [
    "glass1.jpg",
    "carousel7.jpeg",
    "PicsArt 2.jpg",
    "PicsArt 4.jpg",
    "PicsArt (10).jpg",
    "PicsArt.png",
    "newwindow1.jpg",
    "newwindow2.jpg",
    "newwindow3.jpg",
    "newwindow4.jpg",
    "newwindow5.jpg",
    "PicsArt (5).png",
    "PicsArt (17).jpg",
    "PicsArt (2).png",
    "PicsArt (9).jpg",
    "PicsArt (19).jpg",
    "newwindow6.jpg",
    "newwindow7.jpg",
    "newwindow8.jpg",
    "newwindow9.jpg",
    "newwindow10.jpg",
    "PicsArt (11).jpg",
    "PicsArt (6).png",
    "PicsArt (12).jpg",
    "PicsArt (1).jpg",
    "PicsArt 8.png",
    "PicsArt (4).png",
    "pic2.png",
    "newwindow11.jpg",
    "Image2.jpg",
    "Image10.jpg",
    "Image11.jpg",
    "Image12.jpg",
    "PicsArt (18).jpg",
    "windowflower.jpg",
    "window10.png",
    "window11.png",
    "window12.png",
    "jesus.jpg",
  ];
  const images = array.map((image) => {
    return (
      <img
        style={{ padding: "1px", marginTop: "5%" }}
        alt="imag"
        src={require(`./image/${image}`)}
        className="zoom"
      />
    );
  });

  return (
    <div style={{ backgroundColor: "white" }}>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: " auto",
        }}
      >
        <b>Window Panel</b>
      </h1>
      <Slide left>
        {" "}
        <div>{images}</div>
      </Slide>
    </div>
  );
}

export default Window;
