import React from "react";
import "../../App.css";
import Slide from "react-reveal/Slide";

function SunCatchers() {
  const array = [
    "newsun1.jpg",
    "newsun2.jpg",
    "newsun3.jpg",
    "newsun4.jpg",
    "newsun5.jpg",
    "newsun6.jpg",
    "newsun7.jpg",
    "newsun8.jpg",
    "newsun9.jpg",
    "newsun10.jpg",
    "newsun11.jpg",
    "newsun12.jpg",
    "newsun13.jpg",
    "newsun14.jpg",
    "newsun15.jpg",
    "newsun16.jpg",
    "newsun17.jpg",
    "newsun18.jpg",
    "newsun19.jpg",
    "newsun20.jpg",
  ];
  const images = array.map((image) => {
    return (
      <img
        style={{ padding: "1px", marginTop: "5%" }}
        alt="imag"
        src={require(`./image/${image}`)}
        className="zoom"
      />
    );
  });

  return (
    <div style={{ backgroundColor: "white" }}>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: " auto",
        }}
      >
        <b>Sun Catchers</b>
      </h1>
      <Slide left>
        {" "}
        <div>{images}</div>
      </Slide>
    </div>
  );
}

export default SunCatchers;
