import React from 'react'
import '../../App.css';
import AboutUs from '../AboutsUs'
import Image from './image/about.jpg'
function About() {
  return (
    <div className="serviceAbout">
    <img src={Image} alt="GLASS" className="Pic" / >
     <AboutUs/>
    
    
     
    </div>
  )
}

export default About


