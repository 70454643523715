import React from 'react'
import '../../App.css';

function Glass() {
    const array=["PicsArt (15).jpg","PicsArt (14).jpg","PicsArt (20).jpg",]
  const images = array.map(image => {
     return <img style={{padding:'1px' }} alt="imag"  src={require(`./image/${image}`)}  className="zoom"/>});


  return  <div   style={{backgroundColor:"white"}}>
  
  <div >{images  }</div> </div>
}

export default Glass
