// import React from 'react';
// import '../App.css';
// import './HeroSection.css';
// import { Carousel } from 'antd';
// import ReactDOM from 'react-dom';
// import 'antd/dist/antd.css';

// const contentStyle = {
//   height: '160px',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: 'yellow',
// };

// function HeroSection() {
//   return (
//     <div >

//       <h1>M & M Glass Garden</h1>
//       <p>Handmade stained glass manufactures </p>
//       <Carousel autoplay>
//     <div>
//       <h3 style={contentStyle}>1</h3>
//     </div>
//     <div>
//       <h3 style={contentStyle}>2</h3>
//     </div>
//     <div>
//       <h3 style={contentStyle}>3</h3>
//     </div>
//     <div>
//       <h3 style={contentStyle}>4</h3>
//     </div>
//   </Carousel>
//     </div>
//   );
// }

// export default HeroSection;

import React from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import Image from "./pages/image/newwindow11.jpg";
import Image1 from "./pages/image/newsun13.jpg";
import Image2 from "./pages/image/carousel5.jpeg";
import Image3 from "./pages/image/carousel7.jpeg";
import styled from "styled-components";

import Hero from "./Hero";
const CarouselUI = ({ children }) => <Container>{children}</Container>;

const Carousel = makeCarousel(CarouselUI);
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 550px;

  .image {
    width: 1550px;
    height: 700px;
  }

  @media (max-width: 420px) {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
    .image {
      width: 100%;
      height: 100%;
    }
  }
`;

function HeroSection() {
  return (
    <div>
      <Carousel defaultWait={5000} /*wait for 1000 milliseconds*/>
        <Slide right>
          <div>
            <img src={Image} alt="glass" className="image"></img>
            <Hero />
          </div>
        </Slide>
        <Slide right>
          <div>
            <img src={Image3} alt="glass" className="image"></img>
            <Hero />
          </div>
        </Slide>
        <Slide right>
          <div>
            <img src={Image1} alt="glass" className="image" />
          </div>
        </Slide>
        {/*<Slide right>
          <div>
            <img src={Image2} alt="glass" className="image" />
          </div>
       </Slide>*/}
      </Carousel>
    </div>
  );
}

export default HeroSection;

// import React from 'react'
// import makeCarousel from 'react-reveal/makeCarousel';
// import styled from 'styled-components';
// import Slide from 'react-reveal/Slide';
// import Image from './pages/image/carousel1.jpg'
// const Container = styled.div`
//   border: 1px solid red;
//   position: relative;
//   overflow: hidden;
//   width: 300px;
//   height: 150px;
// `;

// const CarouselUI = ({ children }) => <Container>{children}</Container>;
// const Carousel = makeCarousel(CarouselUI);

// function HeroSection() {
//   return (
//     <Carousel defaultWait={1000} /*wait for 1000 milliseconds*/ >
//     <Slide right>
//       <div>
//       <img src={Image} alt="glass"  className="image"  ></img>
//         <h1 style={{color:'black'}}>Slide 1</h1>
//         <p>Slide Description</p>
//       </div>
//     </Slide>
//     <Slide right>
//       <div>

//         <h1 style={{color:'white'}}>Slide 2</h1>
//         <p  style={{color:'white'}}>Slide Description</p>
//       </div>
//     </Slide>
//   </Carousel>
//   )
// }

// export default HeroSection
