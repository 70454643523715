import React from 'react'
import './AboutUs.css';
import Image from './pages/image/images.jfif'


function AboutsUs() {
    return (
      <div> 
      
     
      <p  className="About-p"> 
      <h1 className="About-h1">About Us</h1>
      Our factory is located at Bangalore, Karnataka (India) and is equipped with necessary machines for the bulk production. 
      The premises have all the facilities such as 24 hrs. water and electricity supply, 
      proper power back up arrangement and connectivity with various modes of transportation needed for smooth business activity. 
      Further, we are also backed by the service support of expert professionals, who are experienced and trained in all 
      the processes involved in glass manufacturing.<br/><br/>
     
     We teach beginning, intermediate, 
      and advanced glass art classes and stock a large selection of products--including over 1,000 different kinds of glass!  
      We offer custom design and construction of pieces made to your individual taste, either in stained glass.  
      We also sell a large array of finished glass art, including stained glass panels and other unique gifts.<br/><br/>
      
      We are successful because artistic quality is always our first priority... ALWAYS.
    And because of our years of experience and ability to competently complete any size project, big or small. 
     We hope to hear from you soon!
     <img src={Image} alt="logo" className="smile"></img>
      </p>
     
      
     
     
    </div>
              
            
           
          
        
    )
}

export default AboutsUs

