// import React from 'react'

// function LogIn() {

// const user=[{username:"siri",password:123},{username:"madhu",password:143}]
//    let username;
//    let password;


//   function handleSubmit(e){
//     e.preventDefault()
//            const b=user.find(function (data) {
//                 if(data.username===username && data.password===password){
//                     return true
//                 }
//                 else{
//                     return false
//                  }

//             })
//             if(b===undefined){
//                 console.log("sucessfull");
//        }
//    }
//     function handleUsername(event){
//             username=event.target.value; 
//              console.log(username); 
//            }
//     function handlePassword(event){
//                password=event.target.value;
//        }
//     return(
//         <form>
//           <label>
//             <p>Username</p>
//             <input type="text" onChange={handleUsername} />
//           </label>
//           <label>
//             <p>Password</p>
//             <input type="password" onChange={handlePassword} />
//           </label>
//           <div>

//             <button type="submit" onChange={handleSubmit}>Submit</button>

//           </div>
//         </form>
//       )
//     }


// export default LogIn




// import React, { useState } from 'react'

// import { Redirect } from 'react-router-dom';
// import { Button } from '../Button';


// function Login() {
//     const [Jump, setJump] = useState(true)
//     const user = [{ username: "siri", password: 123 }, { username: "madhu", password: 143 }]
//     let username;
//     let password;
//     let log;
//     function handleun(event) {
//         username = event.target.value;
//         console.log(username);
//     }
//     function handlepd(event) {
//         password = event.target.value;
//     }
    
  
//     return (
//         <div >
//             <form>


//                 <label>
//                     <p>Username</p>
//                     <input type="text" maxLength="20" onChange={handleun} required/><br></br>
//                 </label>
//                 <label>
//                     <p>Password</p><input type="password" maxLength="10" onChange={handlepd} required/><br></br>

//                 </label>
//                 <div>
//                  {/* <button onClick={handlelogin} type="click">login</button>
//     <button onClick={() => setJump(false)}>Submit</button>*/}
//     <button></button>

//                 </div>
//             </form>


//         </div>
//     )

// }
// export default Login;





// import React from 'react'

// function LogIn(){
//     var user1={name:'',username:'',password:''}
//     let user=[]
//     let newuser
// let name;
// let username;
// let password;
// let register;
// let login;
// function handlename(e){
//     name=e.target.value;
    
// }
// function handleun(e){
//     username=e.target.value;
    
// }
// function handlepd(e){
//     password=e.target.value;
   
// }
// function handlereg(e) {
//     e.preventDefault()
//     console.log(name)
//     console.log(username);
//     console.log(password);
//     newuser=user.push(user1);
//     console.log(user);
//     console.log(newuser);
//     alert("registerd sucessfull")
// }
// function handlelogin(event){
//     event.preventDefault()
//     const b=user.find(function (data) {
//         if(data.username===username && data.password===password){
//             return true
//         }
//         else{
//             return false
//         }
    
//     })
//     if(b===undefined){
//         alert("sucessfull");
//     }
//     else{
//         alert("unsucessfull")
//     }
// }
// return(
//     <div>
//     <form style={{color:'black'}}>
//     <h1 style={{color:'black'}}><u>Register</u></h1>
//     <img src="img.webp" height="100" width="100" onChange={handlename}></img><br></br>
//     Name:<input type="text"  maxlength="10" onChange={handleun}/><br></br>
//     Username:<input type="text"  maxlength="20" onChange={handlepd}/><br></br>
//      Password:<input type="password" maxlength="6"/><br></br>
//     <button onClick={handlereg} type="onclick">Register</button>
//     </form>
//     <form style={{color:'black'}}>
   
//     <h1 style={{color:'black'}}><u>Login page</u></h1>
//     <img src="img1.png" height="100" width="100"></img><br></br>
//         Username:<input type="text" maxLength="20"  onChange={handleun}/><br></br>
//         Password:<input type="password" maxLength="10" onChange={handlepd} /><br></br>
//         <button onClick={handlelogin} type="click">login</button>
    
//     </form>
//     <table border="5px" >
//     <tr>
//     <th>username</th>
//     <th>password</th></tr>
//      {
//      newuser.map((value,index)=>
//         <tr><td>{value.username}</td><td>{value.password}</td></tr>)
//     }

    
//     </table>
//     </div>
    
// )

// }
// export default LogIn




import React, { useState } from 'react';
import { Redirect } from 'react-router';
function LogIn() {
 
  const [Jump, setJump]= useState(true);
  let set;
  function handleName(e){
    e.preventDefault()
   set=e.target.value;
  }
  function handleSubmit(e) {
    e.preventDefault()
    setJump(set);
    
  }
  if(!Jump){
      return <Redirect  to="/invoice" />
  }
 return(
   <div><form>
   Name:<input type="text" maxlength="20" onChange={handleName}/><br></br>
    <button onClick={handleSubmit}>SUBMIT</button>
    
   </form>
    <h1>{Jump}
    <div><button onClick={()=>setJump(false)} >click me</button></div></h1>

   </div>
 )
}
export default LogIn;



