import React from 'react'
import '../../App.css';
import Slide from 'react-reveal/Slide';

function Gift() {
    const array=["all.png","gift.jpeg","PicsArt (3).png","gift1.jfif"]
  const images = array.map(image => {
    return <img style={{padding:'1px',    marginTop: '5%' }} alt="imag"  src={require(`./image/${image}`)}  className="zoom"/>});


    return (
        <div   style={{backgroundColor:"white"}}>
  <h1 style={{display:'flex',justifyContent:'center',    color: 'red',fontFamily:' auto'}}><b>Gift Items</b></h1>
  <Slide left> <div >{images  }</div>
  </Slide></div>
    )
}

export default Gift
