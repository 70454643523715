import React from 'react';
import '../../App.css';
import Slide from 'react-reveal/Slide';


export default function Products() {
  const array=["PicsArt.png",
 "PicsArt (17).jpg", "PicsArt (9).jpg",
 "all.png",
  "Image11.jpg"
  ]
  const images = array.map(image => {
     return <img style={{padding:'1px',    marginTop: '5%' }} alt="imag"  src={require(`./image/${image}`)}  className="zoom"/>});


  return  <div   style={{backgroundColor:"white"}}>
  <h1  className="About-h1"><b>OUR PRODUCTS</b></h1>
  <Slide right> <div >{images  }</div>
 </Slide></div>
}
