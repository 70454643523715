import React from 'react'
import './Hero.css'
import Zoom from 'react-reveal/Zoom';
function Hero() {
    return (
        <div >
     
        <p  className="Hero-p">     <Zoom right cascade >
       Glass Is Our Passion
       </Zoom>
    
        </p>
      

        </div>
    )
}

export default Hero
